"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import {Menu, HelpCircle, User2, PlusCircle, Ticket, Gift, TrendingUp, Calendar } from "lucide-react";

import { Button } from "@/components/ui/button";
import { cn } from "@/helpers/utils";
import { ThemeToggle } from "@/components/ui/theme-toggle";
import { motion } from "framer-motion";


// Importer User avec dynamic pour éviter les erreurs d'hydratation
const User = dynamic(() => import("./User"), { 
  ssr: false,
  loading: () => (
    <div className="h-8 w-8 flex items-center justify-center rounded-full bg-primary/10 text-primary">
      <User2 className="h-4 w-4" />
    </div>
  )
});

const navItems = [
  { text: "Verified Coupons", href: "/coupons", smallScreenText: "Coupons", icon: Ticket },
  { text: "Free Courses", href: "/free", smallScreenText: "Free", icon: Gift },
  { text: "Popular Courses", href: "/tops", smallScreenText: "Tops", icon: TrendingUp },
];

// Élément de menu spécifique pour les petits écrans
const smallScreenNavItems = [
  { text: "Daily", href: "/daily", smallScreenText: "Daily", icon: Calendar },
];

export function HeaderView ({ toggleDrawer, isDrawerOpen }) {
  const pathname = usePathname();
  const [isUserMounted, setIsUserMounted] = useState(false);
  
  // Effet pour marquer le composant User comme monté après un court délai
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsUserMounted(true);
    }, 100); // Petit délai pour s'assurer que le header est rendu
    
    return () => clearTimeout(timer);
  }, []);
  
  // Réinitialiser la position de scroll au chargement de la page
  useEffect(() => {
    // Réinitialiser la position de scroll au chargement
    window.scrollTo(0, 0);
    
    // Optionnellement, empêcher le scroll automatique lors des changements de route
    const handleRouteChange = () => {
      window.scrollTo(0, 0);
    };
    
    // Nettoyer l'effet si nécessaire
    return () => {
      // Aucun nettoyage nécessaire pour cet effet spécifique
    };
  }, [pathname]); // Se déclenche à chaque changement de route

  // Animation du bouton Submit
  const buttonVariants = {
    hover: { 
      scale: 1.05,
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", 
      transition: { duration: 0.2, type: "spring", stiffness: 400 }
    },
    tap: { 
      scale: 0.95,
      transition: { duration: 0.1 }
    }
  };

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="mx-auto w-full">
        <nav className="flex h-14 items-center justify-between px-4 sm:px-6 lg:px-8 xl:px-12 2xl:px-16">
          {/* Partie gauche: Menu hamburger, logo et navigation mobile */}
          <div className="flex items-center">
            <Button
              variant="ghost"
              size="icon"
              aria-label={isDrawerOpen ? "Close menu" : "Open menu"}
              className="mr-2 h-8 w-8 rounded-full"
              onClick={toggleDrawer}
            >
              <Menu 
                className={cn(
                  "h-5 w-5 text-foreground transition-transform duration-200",
                  isDrawerOpen && "rotate-90"
                )} 
              />
              <span className="sr-only">{isDrawerOpen ? "Close menu" : "Open menu"}</span>
            </Button>
            
            <Link href="/" className="hidden items-center gap-2 sm:flex">
              <span className="font-semibold tracking-wider text-foreground hover:text-primary transition-colors">
                COMIDOC
              </span>
            </Link>
          </div>

          {/* Partie centrale: Navigation desktop */}
          <div className="hidden flex-1 sm:flex justify-center">
            <ul className="flex items-center justify-center space-x-1 md:space-x-2 lg:space-x-4 xl:space-x-6">
              {navItems.map((item) => {
                const Icon = item.icon;
                return (
                  <li key={item.href}>
                    <Link 
                      href={item.href}
                      className={cn(
                        "inline-flex h-9 items-center justify-center rounded-md px-4 py-2 font-semibold transition-colors",
                        "hover:bg-accent hover:text-accent-foreground",
                        "text-sm sm:text-sm md:text-sm lg:text-base xl:text-base 2xl:text-lg",
                        "whitespace-nowrap",
                        pathname === item.href 
                          ? "bg-accent text-accent-foreground" 
                          : "text-foreground/80"
                      )}
                    >
                      <Icon className="h-4 w-4 mr-2 lg:inline-block hidden" />
                      <span className="hidden md:inline">{item.text}</span>
                      <span className="md:hidden">{item.smallScreenText}</span>
                    </Link>
                  </li>
                );
              })}
              
              {/* Bouton Submit */}
              <li>
                <motion.div
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                >
                  <Link href="/submit">
                    <Button
                      variant="outline"
                      className={cn(
                        "h-9 bg-gradient-to-r from-primary/10 to-primary/20 border-primary/20 hover:bg-gradient-to-r hover:from-primary/20 hover:to-primary/30",
                        "text-primary hover:text-primary font-semibold text-sm lg:text-base 2xl:text-lg",
                        "flex items-center gap-1.5 px-5",
                        pathname === "/submit" && "bg-primary/30 border-primary/40"
                      )}
                    >
                      <PlusCircle className="h-4 w-4 lg:inline-block hidden" />
                      Submit
                    </Button>
                  </Link>
                </motion.div>
              </li>
            </ul>
          </div>

          {/* Navigation mobile - au milieu du header */}
          <div className="sm:hidden flex-shrink-0">
            <ul className="flex items-center space-x-1.5">
              {[...smallScreenNavItems, ...navItems].map((item) => {
                const Icon = item.icon;
                return (
                  <li key={item.href}>
                    <Link 
                      href={item.href}
                      className={cn(
                        "inline-flex h-8 items-center justify-center rounded-md px-2 py-1 text-xs font-medium transition-colors",
                        "hover:bg-accent hover:text-accent-foreground",
                        pathname === item.href 
                          ? "bg-accent text-accent-foreground" 
                          : "text-foreground/80"
                      )}
                    >
                      <Icon className="h-3 w-3 mr-0.5" />
                      {item.smallScreenText}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Partie droite: Boutons d'action */}
          <div className="flex items-center gap-1.5 sm:gap-3 md:gap-4 lg:gap-5">
            {/* Bouton de bascule de thème */}
            <ThemeToggle />
            
            <Link
              href="/faq"
              aria-label="Help and FAQ"
              className="rounded-full p-0.5 sm:p-1 hover:bg-accent transition-colors"
            >
              <HelpCircle className="h-5 w-5 sm:h-6 sm:w-6 text-foreground/80 hover:text-foreground" />
            </Link>
            
            {/* Afficher le composant User seulement après le montage, avec l'icône User non connecté en attendant */}
            {isUserMounted ? (
              <User />
            ) : (
              <Link 
                href="/signup" 
                className="h-7 w-7 sm:h-8 sm:w-8 flex items-center justify-center rounded-full bg-primary/10 text-primary hover:bg-primary/20 transition-colors"
                aria-label="Sign Up"
              >
                <User2 className="h-3.5 w-3.5 sm:h-4 sm:w-4" />
              </Link>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};


