"use client";
import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import DrawerItem from "./DrawerItem";

export function DrawerView({ toggleDrawer, isDrawerOpen }) {
  // Empêcher le défilement du body quand le drawer est ouvert
  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isDrawerOpen]);

  return (
    <AnimatePresence>
      {isDrawerOpen && (
        <>
          {/* Overlay */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 z-40 bg-background/80 backdrop-blur-sm"
            onClick={toggleDrawer}
            aria-hidden="true"
          />
          
          {/* Drawer */}
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ type: "spring", damping: 25, stiffness: 300 }}
            className="fixed inset-y-0 left-0 z-50 w-80 bg-background shadow-lg"
          >
            <div className="flex h-14 items-center justify-between border-b px-4">
              <h2 className="text-lg font-semibold">Menu</h2>
              <Button
                variant="ghost"
                size="icon"
                onClick={toggleDrawer}
                aria-label="Close menu"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            
            <ScrollArea className="h-[calc(100vh-3.5rem)]">
              <div className="p-4 flex-col flex gap-4">
                <div className="space-y-1 mt-4">
                  <h3 className="px-2 text-sm font-medium text-muted-foreground">COURSES</h3>
                  <DrawerItem href="/daily" text="Daily Freebies" handleClick={toggleDrawer} />
                  <DrawerItem href="/coupons" text="Verified Coupons" handleClick={toggleDrawer} />
                  <DrawerItem href="/free" text="Free Courses" handleClick={toggleDrawer} />
                  <DrawerItem href="/tops" text="Most Enrolled" handleClick={toggleDrawer} />
                </div>
                
                <div className="mt-6 space-y-1">
                  <h3 className="px-2 text-sm font-medium text-muted-foreground">COMMUNITY</h3>
                  <DrawerItem href="/submit" text="Submit Coupon" handleClick={toggleDrawer} />
                  <DrawerItem href="/contributors" text="Top Contributors" handleClick={toggleDrawer} />
                  <DrawerItem href="/deals" text="Exclusive Deals" handleClick={toggleDrawer} />
                </div>
                
                <div className="mt-6 space-y-1">
                  <h3 className="px-2 text-sm font-medium text-muted-foreground">EXTENSIONS</h3>
                  <DrawerItem 
                    url="https://chrome.google.com/webstore/detail/comidoc/enggccnigdjpfjajmgicpdmbdfcecbfi" 
                    text="Chrome Extension" 
                    handleClick={toggleDrawer}
                    badge="updated"
                  />
                  <DrawerItem 
                    url="https://addons.mozilla.org/en-US/firefox/addon/comidoc/" 
                    text="Firefox Extension" 
                    handleClick={toggleDrawer} 
                  />
                  <DrawerItem 
                    url=" https://apps.apple.com/us/app/comidoc/id1596445383" 
                    text="Safari Extension" 
                    handleClick={toggleDrawer} 
                    badge="updated"
                  />
                 
                  <DrawerItem 
                    url="https://microsoftedge.microsoft.com/addons/detail/gbgjpkbfcgbabafkikbfdhiagogbdnke" 
                    text="Edge Extension" 
                    handleClick={toggleDrawer} 
                  />
                </div>
                
                <div className="mt-6 space-y-1">
                  <h3 className="px-2 text-sm font-medium text-muted-foreground">SOCIAL</h3>
                  <DrawerItem 
                    url="https://twitter.com/comidoc" 
                    text="Twitter Account" 
                    handleClick={toggleDrawer} 
                  />
                  <DrawerItem 
                    url="https://t.me/online_courses_tracker" 
                    text="Telegram Channel" 
                    handleClick={toggleDrawer} 
                  />
                  <DrawerItem 
                    url="https://comidoc.net/rss" 
                    text="RSS Feed" 
                    handleClick={toggleDrawer} 
                  />
                </div>
                
                <div className="mt-6 space-y-1">
                  <h3 className="px-2 text-sm font-medium text-muted-foreground">SUPPORT</h3>
                  <DrawerItem href="/faq" text="Help" handleClick={toggleDrawer} />
                  <DrawerItem href="/contact" text="Contact" handleClick={toggleDrawer} />
                  <DrawerItem href="/privacy" text="Privacy" handleClick={toggleDrawer} />
                </div>
              </div>
            </ScrollArea>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
