"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ExternalLink } from "lucide-react";
import { cn } from "@/helpers/utils";
import { Badge } from "@/components/ui/badge";

export default function DrawerItem({
  href,
  text,
  className = "",
  url,
  handleClick,
  badge,
}) {
  const pathname = usePathname();
  const isActive = href && pathname === href;
  
  const linkClasses = cn(
    "flex w-full items-center rounded-md px-3 py-2 text-sm font-medium transition-colors",
    isActive 
      ? "bg-accent text-accent-foreground" 
      : "hover:bg-accent/50 hover:text-accent-foreground",
    className
  );
  
  // Normaliser la casse du badge pour éviter les incohérences
  const badgeText = badge ? badge.toLowerCase() : null;
  
  if (url) {
    return (
      <a 
        href={url} 
        target="_blank" 
        rel="noreferrer noopener"
        className={linkClasses}
        onClick={handleClick}
      >
        <div className="flex items-center flex-1">
          <span>{text}</span>
          {badge && (
            <div className="relative ml-2">
              {badgeText === "updated" ? (
                <div className="relative">
                  <span className="absolute inset-0 rounded-md opacity-50 bg-emerald-500 dark:bg-emerald-600 animate-ping"></span>
                  <Badge className="relative bg-emerald-500 hover:bg-emerald-600 text-white dark:bg-emerald-600 dark:hover:bg-emerald-500 font-light tracking-wide text-[10px] px-1.5 py-0 h-5">
                    {badge}
                  </Badge>
                </div>
              ) : (
                <Badge variant="secondary" className="text-[10px] px-1.5 py-0 h-5">
                  {badge}
                </Badge>
              )}
            </div>
          )}
        </div>
        <ExternalLink className="h-3.5 w-3.5 opacity-70" />
      </a>
    );
  }
  
  return (
    <Link 
      href={href} 
      className={linkClasses}
      onClick={handleClick}
      prefetch={false}
    >
      <div className="flex items-center flex-1">
        <span>{text}</span>
        {badge && (
          <div className="relative ml-2 -mt-1">
            {badgeText === "updated" ? (
              <div className="relative">
                <span className="absolute inset-0 rounded-md opacity-50 bg-emerald-500 dark:bg-emerald-600 animate-ping"></span>
                <Badge className="relative bg-emerald-500 hover:bg-emerald-600 text-white dark:bg-emerald-600 dark:hover:bg-emerald-500 font-normal tracking-wide text-[10px] px-1.5 py-0 h-5">
                  {badge}
                </Badge>
              </div>
            ) : (
              <Badge variant="secondary" className="text-[10px] px-1.5 py-0 h-5">
                {badge}
              </Badge>
            )}
          </div>
        )}
      </div>
    </Link>
  );
}
